
import { combineReducers } from "redux";
import { createMultilanguageReducer } from "redux-multilanguage";
import userReducer from "./reducer/userReducer";
import locationReducer from "./reducer/locationReducer";

const rootReducer = combineReducers({
  multilanguage: createMultilanguageReducer({ currentLanguageCode: "en" }),
  user: userReducer,
  location:locationReducer
});

export default rootReducer;