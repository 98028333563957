import actions from "../actions/locationAction";

const initialState = {
  unparsedAddress: null,
  city: null,
  state: null,
  country: null,
  zipCode: null,
  lat: null,
  lng: null,
  streetName: null,
  streetNumber: null,
  locationDetails: {},
};
const {
  SET_UNPARSED_ADDRESS,
  SET_CITY,
  SET_STATE,
  SET_COUNTRY,
  SET_ZIP_CODE,
  SET_LAT,
  SET_LNG,
  SET_STREET_NAME,
  SET_STREET_NUMBER,
  SET_DETAILS
} = actions;
const locationReducer = (globalState = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case SET_UNPARSED_ADDRESS:
      return {
        ...globalState,
        unparsedAddress: data,
      };
    case SET_CITY:
      return {
        ...globalState,
        city: data,
      };
    case SET_STATE:
      return {
        ...globalState,
        state: data,
      };
    case SET_COUNTRY:
      return {
        ...globalState,
        country: data,
      };
    case SET_ZIP_CODE: {
      return {
        ...globalState,
        zipCode: data,
      };
    }
    case SET_LAT: {
      return {
        ...globalState,
        lat: data,
      };
    }
    case SET_LNG:
      return {
        ...globalState,
        lng: data,
      };
    case SET_STREET_NAME:
      return {
        ...globalState,
        streetName: data,
      };
    case SET_STREET_NUMBER:
      return {
        ...globalState,
        streetNumber: data,
      };
    case SET_DETAILS:
      return {
        ...globalState,
        locationDetails: data,
      };
    default:
      return globalState;
  }
};

export default locationReducer;
