/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { useDispatch } from "react-redux";
import actions from "../redux/actions/userAction";
import { motion } from "framer-motion";

/* IMAGE */
import Placeholder from "../assets/featured-home-placeholder.jpg";

/* APIS */
import { getMLS } from "../service/api";
import Skeleton from "react-loading-skeleton";

const PropertyBoxLoader = () => {
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 0.3 }}
      variants={{
        visible: { opacity: 1 },
        hidden: { opacity: 0 },
      }}
      className="property-box"
    >
      <img
        src={Placeholder}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = Placeholder;
        }}
        alt="Property Box"
      />
      <div className="bg-overlay">
        <div className="info-box">
          <div>
            <Skeleton className="street-loading" />
            <Skeleton className="city-loading" />
          </div>
        </div>
      </div>
    </motion.div>
  );
};

const PropertyBox = (props) => {
  return (
    <Link
      to={`/property-detail/${props?.mlsNumber}/${props?.mlsKey}`}
      style={{ textDecoration: "none", color: "#000" }}
    >
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="property-box"
      >
        {props?.propertyImg === null ? (
          <img
            src={Placeholder}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = Placeholder;
            }}
            alt={props.streetName}
          />
        ) : (
          props?.propertyImg
            ?.filter((img) =>
              img?.MediaURL?.includes(
                "https://api-trestle.corelogic.com/trestle/Media/CRMLS/Property/PHOTO-jpeg/" +
                  props?.mlsNumber +
                  "/1/"
              )
            )
            .map((img) => {
              return (
                <img
                  src={img?.MediaURL}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = Placeholder;
                  }}
                  alt={props.streetName}
                />
              );
            })
        )}
        <div className="bg-overlay">
          <div className="info-box">
            <div>
              <h5>{props.streetName}</h5>
              <p>{props.cityName}</p>
            </div>
          </div>
        </div>
      </motion.div>
    </Link>
  );
};

const ActiveListings = () => {
  const [page, setPage] = useState(1);
  const [MLSLoading, setMLSLoading] = useState(false);
  const [data, setData] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [total, setTotal] = useState(0);
  const dispatch = useDispatch();

  /* DEFAULT FILTERS */
  let defaultFilters = {
    page: page,
    isFilter: true,
    propertyStatus: ["Active"],
    minBedroom: null,
    maxBedroom: null,
    minPrice: 1,
    maxPrice: null,
    minBath: null,
    maxBath: null,
    location: null,
    featured: true,
    propertySubType: [],
    propertyType: [],
    minYearBuilt: null,
    maxYearBuilt: null,
    minDaysOnMarket: null,
    maxDaysOnMarket: null,
    minLotSize: null,
    maxLotSize: null,
    minLotSizeAcres: null,
    maxLotSizeAcres: null,
    minLatitude: null,
    maxLatitude: null,
    minLongitude: null,
    maxLongitude: null,
    sortBy: null,
  };

  const [params, setParams] = useState(defaultFilters);

  /* GET MLS DATA */
  const getMLSData = () => {
    setMLSLoading(true);

    let datas = params;
    let obj = datas;
    obj.page = page;
    obj.featured = true;

    getMLS(obj)
      .then((res) => {
        if (res.status) {
          let list = [];
          let length = 0;
          res?.data?.map((obj) => {
            if (obj?.value?.length > 0) {
              list = list.concat(obj?.value);
              length += obj["@odata.count"];
            }
          });
          setData(list.reverse());
          if (length > 0) {
            list?.forEach((element) => {
              setMarkers((current) => [
                ...current,
                {
                  lat: element?.Latitude,
                  lng: element?.Longitude,
                  data: element,
                  time: new Date(),
                },
              ]);
            });
          }
          setTotal(length);
          setTotalPages(length / 20);
          dispatch(actions.setMLS(res?.data[1]?.value));
        }
      })
      .finally((res) => {
        setMLSLoading(false);
      });
  };

  /* USEEFFECT */
  useEffect(() => {
    getMLSData();
  }, [page]);

  console.log("Feattured Listings: ", data);

  return (
    <motion.section
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 0.3 }}
      variants={{
        visible: { opacity: 1 },
        hidden: { opacity: 0 },
      }}
      className="active-listings"
    >
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="container"
      >
        <div className="heading">
          <h2>FEATURED LISTINGS</h2>
          <Link to="/featured-listings" className="listings-link">
            View all listings
          </Link>
        </div>
      </motion.div>
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="row mt-4"
      >
        <Swiper
          spaceBetween={30}
          breakpoints={{
            300: {
              slidesPerView: 1,
            },
            400: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 3,
            },
            1000: {
              slidesPerView: 6,
            },
          }}
          autoplay
          modules={[Autoplay]}
        >
          {MLSLoading ? (
            <>
              <SwiperSlide>
                <div className="col-xl-12 col-sm-12">
                  <PropertyBoxLoader />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="col-xl-12 col-sm-12">
                  <PropertyBoxLoader />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="col-xl-12 col-sm-12">
                  <PropertyBoxLoader />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="col-xl-12 col-sm-12">
                  <PropertyBoxLoader />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="col-xl-12 col-sm-12">
                  <PropertyBoxLoader />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="col-xl-12 col-sm-12">
                  <PropertyBoxLoader />
                </div>
              </SwiperSlide>
            </>
          ) : (
            false
          )}
          {data?.length > 0 &&
            data?.map((property) => {
              return (
                <SwiperSlide>
                  <div className="col-xl-12 col-sm-12">
                    <PropertyBox
                      propertyImg={property?.Media}
                      streetName={property.UnparsedAddress}
                      cityName={property.City}
                      mlsNumber={property.ListingKey}
                      mlsKey={property.OriginatingSystemName}
                    />
                  </div>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </motion.div>
    </motion.section>
  );
};

export default ActiveListings;
