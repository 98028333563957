import actions from "../actions/userAction";

const initialState = {
  user: null,
  token: null,
  loggedIn: false,
  loading: false,
  MLS: [],
  selectedItem: null,
  selectedAgentId: null,
  latLng: null,
  blogCategory: null,
  leadDetails: null
};
const {
  SET_USER,
  SET_TOKEN,
  SET_LOGGEDIN,
  SET_LOADING,
  SET_MLS,
  SET_ITEM,
  SET_AGENT_ID,
  SET_LAT_LNG,
  SET_BLOG_DETAILS,
  SET_LEAD_DETAILS
} = actions;
const userReducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case SET_USER:
      return {
        ...state,
        user: data,
      };
    case SET_TOKEN:
      return {
        ...state,
        token: data,
      };
    case SET_LOGGEDIN:
      return {
        ...state,
        loggedIn: data,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: data,
      };
    case SET_ITEM: {
      return {
        ...state,
        selectedItem: data,
      };
    }
    case SET_AGENT_ID: {
      return {
        ...state,
        selectedAgentId: data,
      };
    }
    case SET_MLS:
      return {
        ...state,
        MLS: data,
      };
    case SET_LAT_LNG:
      return {
        ...state,
        latLng: data,
      };
    case SET_BLOG_DETAILS:
      return {
        ...state,
        blogCategory: data,
      };
    case SET_LEAD_DETAILS:
      return {
        ...state,
        leadDetails: data,
      };
    default:
      return state;
  }
};

export default userReducer;
